<template>
  <div class="main" :style="mainStyle">
    <h1>Home Services Menu</h1>
    <br>
    <label>Welcome to your account. Please find all home services available to you.</label>
    <div style="margin-top: 40px">
      <h2>Meter readings</h2>
      <label>Here you can view and update your meter readings.</label>
      <br><br>
      <input type="button" value="Your meter readings" class="button"  id="done" :style="inputStyle" @click="showMeterReadingsPage"/>
    </div>
    <div style="margin-top:50px">
      <h2>Payments</h2>
      <label>Payments allow you to submit new payments to your account.</label>
      <br><br>
      <input type="button" value="Your payments" class="button"  id="done" :style="inputStyle" @click="showPaymentsPage"/>
    </div>
    <div style="margin-top:50px">
      <h2>Logout</h2>
      <input type="button" value="Logout" class="button"  id="done" :style="inputStyle" @click="logout"/>
    </div>
  </div>
</template>

<script>
import { loginService } from "../services/authentication-service.js";

export default {
  name: 'HomePage',
  props: {
    inputStyle: String,
    mainStyle: String
  },
  methods: {
    async showMeterReadingsPage() {
      this.$emit('showMeterReadings', 'true');  
    },
    async showPaymentsPage() {
      this.$emit('showPayments', 'true');  
    },
    logout() {
      loginService.expireCookie();
      this.$emit('logout', 'true');
    }
  }
}

// 'ajs_anonymous_id=7fe28e40-bb79-4f62-98af-9481efef8961; {"emailAddress":"synthetic@synthetic.com","custName":"Synthetic Canary","meterId":"1"}; expires=Fri, 03 Nov 2023 13:36:26 GMT'
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
