<template>
  <div  v-if="!showReg" class="main" :style="mainStyle">
    <h3>Your login details</h3>
    <!-- <input
      v-model="email"
      type="text"
      placeholder="Email address"
      :style="inputStyle"
    /> -->
    <input
      v-model="email"
      type="text"
      placeholder="Email address"
      :style="inputStyle"
    />
    <br />
    <input
      type="button"
      value="Login"
      class="button"
      :style="inputStyle"
      @click="login"
    />
    <easy-spinner v-if="spinner" size="25" color="green" />
    <input
      type="button"
      value="Register"
      class="button"
      :style="inputStyle"
      @click="showRegistrationPage"
    />
  </div>
  <RegistrationPage v-if="showReg" @registered="onRegistered" @alreadyRegistered="onAlreadyRegistered"/>
</template>
  
  <script>
  import { loginService } from "../services/authentication-service.js";
  import RegistrationPage from '../components/RegistrationPage.vue';

export default {
  name: "LoginPage",
  //Custom style for main and input for make the page responsive:
  props: {
    mainStyle: String,
    inputStyle: String,
  },
  data() {
    return {
      showReg: false,
      spinner: undefined
    }
  },
  methods: {
    onAlreadyRegistered(value) {
      //runs when uses registers
      this.showReg = false;
      this.$emit('alreadyRegistered', value);
    },
    onRegistered(value) {
      //runs when uses registers
      this.$emit('loggedIn', value);
    },
    onLoggedIn(value) {
      //alert('User is now logged in.');
      this.showHomePage = value;
      this.showLogin = false;
    },
    showRegistrationPage() {
      this.showReg = true;
    },
    async login() {
      if (!this.email) {
        alert("Please enter your email address to login.");
      }
      else {
        try {
          //await authenticationService.login(username, password); TODO    
          this.spinner = true;
          let response = await loginService.login(this.email.trim());
          if (!response.customer_name && !response.meter_id) {
            alert('Email address not found. Please register before trying to login');
          }
          else {
            loginService.createCookie(this.email.toString(), response.customer_name.toString(), response.meter_id.toString());
            this.$emit('loggedIn', 'true');  
          }
        } catch(error) {
          alert(error);
        }
        this.spinner = false;
      }
    }
  },
  components: {
    RegistrationPage
  }
};
</script>
  
  <style>
/* Import Poppins font: */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.main {
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  /* top: 20%; */
  left: 30%;
  width: 40%;
  text-align: center;
  padding: 5px;
  border-radius: 3rem;
  box-shadow: 0px 0px 8px -5px #000000;
  padding-top: 3%;
  padding-bottom: 5%;
  font-family: "Poppins", sans-serif;
}

h1 {
  cursor: default;
  user-select: none;
}

input {
  border-radius: 1.5rem;
  border: none;
  padding: 20px;
  text-align: center;
  outline: none;
  margin: 10px;
  width: 40%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

input:hover {
  box-shadow: 0px 0px 8px -5px #000000;
}

input:active {
  box-shadow: 0px 0px 8px -5px #000000;
}

.button {
  cursor: pointer;
  user-select: none;
  background: lightgreen;
  width: 200px;
}

img {
  height: 2.2rem;
  margin: 10px;
  user-select: none;
}

img:hover {
  box-shadow: 0px 0px 8px -5px #000000;
  cursor: pointer;
  border-radius: 200rem;
}
</style>