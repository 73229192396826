<template>
  <div class="hello">
    <h1>PAYMENTS</h1>
    <br>
    <label>Here you can submit a new payment to your account.</label>
    <div style="margin-top: 40px">
      <h2>Submit new payment</h2>
     <input v-model="paymentAmount" placeholder="Type in your payment amount." :style="inputStyle"/>
      <br><br>
      <input type="button" value="Submit" class="button"  :style="inputStyle" @click="makePayment"/>
      <easy-spinner v-if="spinner" size="25" color="green" />
    </div>
    <div style="margin-top:60px">
      <input type="button" value="Main menu" class="button" :style="inputStyle" @click="goHome"/>
    </div>
  </div>
</template>

<script>
import { paymentsService } from "../services/payments-service.js";
import { loginService } from "../services/authentication-service.js";

export default {
  name: 'PaymentsPage',
  props: {
    mainStyle: String,
    inputStyle: String,
  },
  data() {
    return {
      spinner: undefined,
    };
  },
  methods: {
    goHome() {
      this.$emit('goHome', 'true');  
    },
    async makePayment() {
      this.spinner = true;
      let json = loginService.getCookie();
      console.log(json)
      let meterId = json.meterId;
      // let paymentAmount = this.paymentAmount.trim();
      let emailAddress = json.emailAddress;
      console.log(emailAddress)
      try {
        let response = await paymentsService.makePayment(emailAddress, meterId, this.paymentAmount);
        if(response.status == 'OK'){
          alert('Payment submitted successfully');
        } else {
          alert('Payment submission failed, please try again!')
        }
      } catch (error) {
        alert(error);
      }
      this.spinner = false;
    }  
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
