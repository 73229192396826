import { AUTHENTICATION_API } from '../shared/config';

const login = async function (emailAddress) {
  var data = undefined;

  try {

    let loginDetails = new FormData();
    loginDetails.append('email_address', emailAddress);

    const response = await fetch(`${AUTHENTICATION_API}/login/`, {
      method: "POST",
      body: loginDetails,
    });

    data = await response.json();

  } catch (error) {
    console.log(error);
    throw new Error("Unable to login at this time");
  }

  return data;
}

const register = async function (emailAddress, custName, meterId) {
  var data = undefined;

  try {

    let regDetails = new FormData();
    regDetails.append('email_address', emailAddress);
    regDetails.append('customer_name', custName);
    regDetails.append('meter_id', meterId);

    const response = await fetch(`${AUTHENTICATION_API}/register/`, {
      method: "POST",
      body: regDetails,
    });

    data = await response.json();

  } catch (error) {
    console.log(error);
    throw new Error("Unable to register at this time");

  }

  return data;
}

const createCookie = function(emailAddress, custName, meterId){
  var cookie = {};
	cookie.emailAddress = emailAddress;
	cookie.custName = custName;
	cookie.meterId = meterId;
 
  var now = new Date();
  now.setTime(now.getTime() + (24 * 3600 * 1000));

  document.cookie = 'resilienceCookie=' + JSON.stringify(cookie);
  document.cookie = "expires=" + now.toUTCString() + "; path=/;";

}

const expireCookie = function(){

  document.cookie = 'resilienceCookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';

}

const getCookie = function(){
  // let cookie_elements = JSON.parse(document.cookie.split(";"));
  let cookie_elements = document.cookie.split(";");
  for (let i = 0; i < cookie_elements.length; i++) {
    if(cookie_elements[i].includes("emailAddress")) {
      let cookie = cookie_elements[i].replace("resilienceCookie=", "");
      return JSON.parse(cookie);
    }
   }
}

export const loginService = {
  login,
  register,
  createCookie,
  getCookie,
  expireCookie
};
