<template>
  <div class="main" :style="mainStyle">
    <h1>METER READINGS</h1>
    <br>
    <label>Here you can view and update meter readings for your account.</label>
    <div style="margin-top: 40px">
      <h2>Meter readings</h2>
      <input type="button" value="View meter readings" class="button" :style="inputStyle" @click="getMeterReadings"/>
      <easy-spinner v-if="spinner" size="25" color="green" />
      <!-- <label>{{ meterReading }}</label> -->
      <table class="table table-bordered center_table" v-if="tableLoaded">
			<thead>
				<tr>
					<th> Meter Reading</th>
					<th> Date and Time</th>
				</tr>
			</thead>
        <tbody>
          <tr v-for="reading in meterReading" :key="reading.meterReading">
            <td>{{ reading.meterReading }}</td>
            <td>{{ reading.timeStamp }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="margin-top: 40px">
      <h2>Submit new meter reading</h2>
      <input v-model="newMeterReading" placeholder="Meter reading" :style="inputStyle"/>
      <br><br>
      <input type="button" value="Submit" class="button"  :style="inputStyle" @click="submitMeterReading"/>
      <easy-spinner v-if="submitSpinner" size="25" color="green" />
    </div>
    <div style="margin-top:60px">
      <input type="button" value="Main menu" class="button" :style="inputStyle" @click="goHome"/>
    </div>
  </div>
</template>

<script>
import { meterService } from "../services/meter-readings-service.js";
import { loginService } from "../services/authentication-service.js";

export default {
  name: 'MeterReadingsPage',
  props: {
    mainStyle: String,
    inputStyle: String,
  },
  data() {
    return {
     meterReading: undefined,
     spinner: undefined,
     submitSpinner: undefined,
     tableLoaded: undefined
    };
  },
  methods: {
    goHome() {
      this.$emit('goHome', 'true');  
    },
    async submitMeterReading() {
      this.submitSpinner = true;
      let json = loginService.getCookie();
      let custName = json.custName;
      let meterId = json.meterId;

      try {
        let response = await meterService.submitMeterReading(custName, meterId, this.newMeterReading);
        if(response.status == 'OK'){
          alert('Meter reading submitted successfully.');
        }
      } catch (error) {
        alert(error);
      }
      this.submitSpinner = false;
    },
    async getMeterReadings() {
      this.spinner = true;
      //'{"emailAddress":"1","custName":"2","meterId":3}; expires=Sat, 02 Sep 2023 15:06:22 GMT'
      let json = loginService.getCookie();
      let custName = json.custName;
      let meterId = json.meterId;

      if(!custName || !meterId){
        alert('Customer name and meter id not available. Login again.');
      }
      else{
        try {
        this.meterReading = await meterService.getMeterReadings(custName, meterId);
        this.tableLoaded = true;
        } catch (error) {
          alert(error);
        }
      }
      this.spinner = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center_table {
  margin-left:auto;
  margin-right:auto;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
