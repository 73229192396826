<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <h1>Unicorn Power Account Management</h1>
  <LoginPage v-if="showLogin" @loggedIn="onLoggedIn" @alreadyRegistered="onAlreadyRegistered" />
  <HomePage v-if="showHomePage" @showMeterReadings="onMeterReadings" @showPayments="onPayments" @logout="onLogout"/>
  <MeterReadingsPage v-if="showMeterReadingsPage" @goHome = "goHome"/>
  <PaymentsPage v-if="showPaymentsPage" @goHome = "goHome"/>
  <!-- <CellRouterPage v-if="showCellRouterPage" msg="Cell router goes here"/> -->
</template>

<script>
import HomePage from './components/HomePage.vue'
import MeterReadingsPage from './components/MeterReadingsPage.vue'
import LoginPage from './components/LoginPage.vue'
import PaymentsPage from './components/PaymentsPage.vue'

export default {
  name: 'App',
  emits: ["loggedIn", "alreadyRegistered"],
  data() {
    return {
      showLogin:undefined,
      showHomePage:undefined,
      showMeterReadingsPage: undefined,
      showPaymentsPage:undefined,
      // showCellRouterPage:undefined,
    }
  },
  methods: {
    onLoaded() {
      this.showLogin = true;
    },
    onLoggedIn(value) {
      //alert('User is now logged in.');
      this.showHomePage = value;
      this.showLogin = false;
    },
    onMeterReadings(value) {
      this.showMeterReadingsPage = value;
      this.showHomePage = false;
    },
    onPayments(value) {
      this.showPaymentsPage = value;
      this.showHomePage = false;
    },
    goHome() {
      this.showHomePage = true;
      this.showMeterReadingsPage = false;
      this.showPaymentsPage = false;
    },
    onLogout() {
      this.showLogin = true;
      this.showHomePage = undefined;
      this.showMeterReadingsPage = undefined;
      this.showPaymentsPage = undefined;
      this.showCellRouterPage = undefined;
    },
    onAlreadyRegistered() {
      this.showLogin = true;
      this.showHomePage = undefined;
      this.showMeterReadingsPage = undefined;
      this.showPaymentsPage = undefined;
      this.showCellRouterPage = undefined;
    }
  },
  created: function(){
        this.onLoaded()
      },
  components: {
    HomePage,
    MeterReadingsPage,
    LoginPage,
    PaymentsPage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
