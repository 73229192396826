import { createApp } from 'vue'
import App from './App.vue'
import easySpinner from 'vue-easy-spinner';

createApp(App)
  .use(easySpinner, {
    /*options*/
    prefix: 'easy',
  })
  .mount('#app');

// createApp(App).mount('#app')
