// import * as axios from 'axios';
import { PAYMENTS_API} from '../shared/config';

const makePayment = async function (emailAddress, meterId, paymentAmount) {
  let data = undefined;

  try {

    let paymentPayload = new FormData();
    paymentPayload.append('payment_amount', paymentAmount);

    const response = await fetch(`${PAYMENTS_API}/make_payment/${emailAddress}/${meterId}`, {
      method: "POST",
      body: paymentPayload,
    });

    data = await response.json();

  }
  catch (error) {
    console.log(error);
    throw new Error("Unable to submit payment at this time");
  }

  return data;
}

export const paymentsService = {
  makePayment
};
