<template>
  <div class="main" :style="mainStyle">
    <h3>Your registration details</h3>
    <input v-model="emailAddress" type="text" placeholder="Email address" :style="inputStyle" />
    <br />
    <input v-model="custName" type="text" placeholder="Customer name" :style="inputStyle" />
    <br />
    <input v-model="meterId" type="text" placeholder="Meter id" :style="inputStyle" />
    <br />
    <input type="button" value="Register" class="button" :style="inputStyle" @click="register" />
    <easy-spinner v-if="spinner" size="25" color="green" />
    <div style="margin-top:50px">
      <h3>Back to Login</h3>
      <input type="button" value="Back to Login" class="button"  id="done" :style="inputStyle" @click="alreadyRegistered"/>
    </div>
  </div>
</template>
  
<script>
import { loginService } from "../services/authentication-service.js";

export default {
  name: "RegistrationPage",
  //Custom style for main and input for make the page responsive:
  props: {
    mainStyle: String,
    inputStyle: String,
  },
  data() {
    return {
      spinner: undefined
    }
  },
  methods: {
    async register() {
      if (!this.emailAddress || !this.custName || !this.meterId) {
        alert("Please enter all your registration details.");
      }
      else {
        try {
          this.spinner = true;
          let response = await loginService.register(this.emailAddress.trim(), this.custName.trim(), this.meterId.trim());
          if (response.message == 'registered') {
            loginService.createCookie(this.emailAddress, this.custName, this.meterId);
            alert('Registration successful.');
            this.$emit('registered', 'true');
          }
          else if (response.status == "ERROR - you've already registered")
            alert("You've already registered, please try again")
        } catch(error) {
          alert(error);
        }
        this.spinner = false;
      }
    },
    alreadyRegistered() {
      loginService.expireCookie();
      this.$emit('alreadyRegistered', 'true');
    }
  },
};
</script>
  
<style>
/* Import Poppins font: */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.main {
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  /* top: 20%; */
  left: 30%;
  width: 40%;
  text-align: center;
  padding: 5px;
  border-radius: 3rem;
  box-shadow: 0px 0px 8px -5px #000000;
  padding-top: 3%;
  padding-bottom: 5%;
  font-family: "Poppins", sans-serif;
}

h1 {
  cursor: default;
  user-select: none;
}

input {
  border-radius: 1.5rem;
  border: none;
  padding: 20px;
  text-align: center;
  outline: none;
  margin: 10px;
  width: 40%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

input:hover {
  box-shadow: 0px 0px 8px -5px #000000;
}

input:active {
  box-shadow: 0px 0px 8px -5px #000000;
}

.button {
  cursor: pointer;
  user-select: none;
  background: lightgreen;
  width: 200px;
}

img {
  height: 2.2rem;
  margin: 10px;
  user-select: none;
}

img:hover {
  box-shadow: 0px 0px 8px -5px #000000;
  cursor: pointer;
  border-radius: 200rem;
}
</style>