// import * as axios from 'axios';
import { METER_READING_API } from '../shared/config';

const submitMeterReading = async function (customer_name, meter_id, meterReading) {
  let data = undefined;

  try {

    let readingPayload = new FormData();
    readingPayload.append('meter_reading', meterReading);

    const response = await fetch(`${METER_READING_API}/submit_meter_reading/${customer_name}/${meter_id}`, {
      method: "POST",
      body: readingPayload,
    });


    data = await response.json();
   
  } catch (error) {
    console.log(error);
    throw new Error("Unable to submit meter reading at this time");
  }

  return data;
}

const getMeterReadings = async function (customer_name, meter_id) {
  let data = undefined;

  try {
    const response = await fetch(
      `${METER_READING_API}/view_meter_readings/${customer_name}/${meter_id}`
    );

    data = await response.json();

  } catch (error) {
    console.log(error);
    throw new Error("Unable to get meter reading at this time");
  }

  return data;
}

export const meterService = {
  submitMeterReading,
  getMeterReadings
};
